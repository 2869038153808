// store.js
import { configureStore } from '@reduxjs/toolkit'
import {apiSlice} from "./api";
import linkReducer from './reducers'
export default configureStore({
  reducer: {
    link: linkReducer,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
