import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { Alert, Group, TextInput, Button } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import DeleteRow from "../components/DeleteRow";

const LinkView = () => {
  const location = useLocation();
  let { user, getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();
  const urlData = location.state.url;
  const urls = location.state.urls;

  let [source, setSource] = useState(urlData.source);
  let [target, setTarget] = useState(urlData.target);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");
  let isOwner = false;
  if (user != null) {
    isOwner = urlData.user_name === user.name;
  }

  const trimWhiteSpace = (text) => {
    return text.split(" ").join("");
  };

  const updateShortLink = async () => {
    // We have to check that it is not a duplicate with itself
    // as the target could be changed without updating the source
    if (urls.some((x) => x.source === source) && source !== urlData.source) {
      setShowMessage(true);
      setMessage("This link already exists");
      setColor("red");
      return;
    }

    const token = await getAccessTokenSilently();
    const response = await fetch("golink", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        original_source: urlData.source,
        user_name: user.name,
        source: source,
        target: target,
      }),
    }).then((response) => {
      if (response.status === 200) {
        setMessage(`Update successful!`);
        setColor("green");
        setShowMessage(true);
      } else {
        setMessage(`An error occured status ${response.status}`);
        setColor("red");
        setShowMessage(true);
      }
    });
  };

  const navigateToHome = () => {
    navigate("/");
  };

  return (
    <div>
      <div onClick={navigateToHome}>Hello Link {urlData.source}</div>
      <div>Owner: {urlData.user_name}</div>
      {showMessage ? (
        <Alert radius="md" icon={<IconAlertCircle size={16} />} color={color}>
          {message}
        </Alert>
      ) : null}
      <TextInput
        className="addSpacing"
        label="Source Url"
        value={source}
        disabled={!isOwner}
        onChange={(event) => setSource(event.currentTarget.value)}
      />
      <TextInput
        className="addSpacing"
        label="Target Url"
        value={target}
        disabled={!isOwner}
        onChange={(event) => setTarget(event.currentTarget.value)}
      />

      {isOwner ? (
        <Group className="addSpacing" spacing="xl" position="right" grow>
          <Button onClick={updateShortLink} variant="outline" color="green">
            {" "}
            Update
          </Button>
          <DeleteRow
            user_name={user.name}
            source={source}
            refresh={navigateToHome}
          />
        </Group>
      ) : null}
    </div>
  );
};
export default LinkView;
