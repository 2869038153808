import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const initialState = {
  showMessage: false,
  message: "",
  color: "",
};

export const showMessageWithTimeout = createAsyncThunk(
  'link/showMessageWithTimeout',
  async (messageDetails, {dispatch}) => {
    const {message, color, timeout} = messageDetails;
    console.log(messageDetails);
    
    dispatch(showMessage({message, color}));
    
    await new Promise(resolve => setTimeout(resolve, timeout));
    dispatch(hideMessage());
  })

const linkSlice = createSlice({
  name: 'link',
  initialState,
  reducers: {
    showMessage: (state, action) => {
      console.log("SHOW MESSAGE")
      console.log(action);
      state.showMessage = true;
      state.message = action.payload.message;
      state.color = action.payload.color;
    },
    hideMessage: (state) => {
      state.showMessage = false;
    },
  }
})

export const {showMessage, hideMessage} = linkSlice.actions;
export default linkSlice.reducer;
