// api.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from './config';
const {appOrigin} = getConfig();

export const apiSlice = createApi({
  reducerPath: 'api', // Unique key for the API state slice
  baseQuery: fetchBaseQuery({ baseUrl: appOrigin }),
  endpoints: (builder) => ({
    submitLink: builder.mutation({
      query: ({ userName, source, target, token }) => ({
        url: 'golink',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: {
          user_name: userName,
          source: source,
          target: target,
        },
      }),
    }),
    // Add other endpoints if needed
  }),
});

export const { useSubmitLinkMutation } = apiSlice;
