import { useAuth0 } from "@auth0/auth0-react";
import Form from "../components/Form";
import {useNavigate, useParams} from "react-router-dom";
import {Alert, Button, Text} from "@mantine/core";
import {IconAlertCircle} from "@tabler/icons";
import {useState} from "react";
import {Nav} from "reactstrap";

const CreateUrl = () => {
  const { url } = useParams();
  let { user, isAuthenticated, loginWithRedirect } = useAuth0();
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");
  
  return (
    <div>
      {showMessage ? (
        <Alert radius="md" icon={<IconAlertCircle size={16}/>} color={color}>
          {message}
        </Alert>
      ) : null
      }
      <h1>You discovered a link that doesn't exist!</h1>
      <Text
        variant="gradient"
        gradient={{ from: 'white', to: 'indigo', deg: 180 }}
        fz="xl"
        fw={700}
      >Create your own destiny</Text>
      {!isAuthenticated ? (
        <div>
          <p>In order to create links please login.</p>
          <Button
            onClick={() =>
              loginWithRedirect({
                redirectUri: window.location.origin,
              })
            }
          >
            Log In
          </Button>
        </div>
      ) : (
        <div>
        <Form
        userName={user.name}
        setShowMessage={setShowMessage}
        setMessage={setMessage}
        setColor={setColor}
        url={url}
        />
        </div>
      )}
    </div>
  );
};

export default CreateUrl;
