import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mantine/core";
const DeleteRow = ({ user_name, source, refresh }) => {
  
  const { getAccessTokenSilently } = useAuth0();

  const deleteRow = async ({ event }) => {
    const token = await getAccessTokenSilently();
    const response = await fetch("golink", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        user_name: user_name,
        source: source,
      }),
    });
    if (response.status === 200){
      refresh();
    }
  };

  return (
    <Button variant="outline" color="red" onClick={deleteRow}>
      Delete
    </Button>
  );
};

export default DeleteRow;
