import React, { useEffect, useState } from "react";
import "./Urls.css";
import UrlTable from "../components/UrlTable";
import { TextInput } from "@mantine/core";

const Urls = ({urls, setUrls}) => {
  const [filteredUrls, setFilteredUrls] = useState([]);
  const [filterText, setFilterText] = useState('');
  
  useEffect(() => {
    setFilteredUrls(filterData(filterText));
  },[urls]);
  
  const filterData = (filterText) => {
    if (filterText === "" || filterText === null) {
      return urls;
    } else {
      return urls.filter(
        (x) =>
          x.source.includes(filterText) ||
          x.target.includes(filterText) ||
          x.user_name.includes(filterText)
      );
    }
  };

  const refresh = () => {
    populateUrlData().then((data) => {
      setUrls(data);
      setFilteredUrls(data);
    });
  };

  useEffect(() => {
    refresh();
  }, []);

  const populateUrlData = async () => {
    const response = await fetch("golink");
    return await response.json();
  };

  let inputHandler = (e) => {
    e.preventDefault();
    // TODO: fuzzy searching
    setFilterText(e.target.value);
    setFilteredUrls(filterData(e.target.value));
  };

  return (
    <div className="main">
      <h1 id="tabelLabel">Existing Shortlinks</h1>
      <div className="search">
        <TextInput
          id="outlined-basic"
          onChange={inputHandler}
          placeholder="Search"
        />
      </div>
      <UrlTable urls={filteredUrls} />
    </div>
  );
};

export default Urls;
