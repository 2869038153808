import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import "./Form.css";
import {Group, TextInput, Button} from "@mantine/core";
import {showMessageWithTimeout} from "../reducers";
import {useDispatch} from "react-redux";
import {useSubmitLinkMutation} from "../api";

const Form = ({
                userName,
                url,
                setUrls,
              }) => {
  const [source, setSource] = useState("");
  const [target, setTarget] = useState("");
  const [existingUrls, setExistingUrls] = useState([]);

  const {getAccessTokenSilently} = useAuth0()

  const dispatch = useDispatch()

  const [submitLink, {isLoading, isError, isSuccess}] = useSubmitLinkMutation();

  const populateUrlData = async () => {
    const response = await fetch("golink");
    return await response.json();
  };

  const refresh = () => {
    populateUrlData().then((data) => {
      setExistingUrls(data);
      if (setUrls !== undefined) {
        setUrls(data);
      }
      setTimeout(() => console.log("refresh data"), 1000);
    });
  };

  useEffect(() => {
    if (url !== undefined) {
      setSource(url);
    }
    refresh();
  }, [url]);


  // Rewrite handle submit to use redux instead 

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = await getAccessTokenSilently();

    if (existingUrls.some((x) => x.source === source)) {
      console.log("Dispatching!!");
      dispatch(showMessageWithTimeout({ message: "This link already exists", color: "yellow", timeout: 3000 }))
      return;
    }

    try {
      await submitLink(
        {
          userName,
          source,
          target,
          token
        }).unwrap();
      dispatch(showMessageWithTimeout({
        message: "Link submitted successfully",
        color: "green",
        timeout: 3000
      }));
    } catch (error) {
      console.log(error);
      dispatch(showMessageWithTimeout({
        message: "Error submitting link",
        color: "red"
      }));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Group spacing="xl" grow>
        <TextInput
          type="text"
          label="Short Link"
          value={source}
          onChange={(event) => setSource(event.target.value)}
          placeholder="youtube"
          required
        />

        <TextInput
          type="text"
          label="Redirect URL"
          value={target}
          onChange={(event) => setTarget(event.target.value)}
          placeholder="https://youtube.com"
          required
        />
      </Group>
      <Group position="right">
        <Button type="submit" variant="outline" color="green">
          Create Link
        </Button>
      </Group>
    </form>
  );
};

export default Form;
