import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { Table } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import './UrlTable.css'

const UrlTable = ({ urls }) => {
  const { user, isAuthenticated } = useAuth0();
  const [localUrls, setLocalUrls] = useState(urls);
  const [isAscending, setIsAscending] = useState(true);
  const navigate = useNavigate();

  const trimWhiteSpace = (text) => {
    return text.split(" ").join("");
  };

  const sortByField = (field) => {
    if (isAscending) {
      setLocalUrls(urls.sort((a, b) => (a[field] > b[field] ? 1 : -1)));
    } else {
      setLocalUrls(urls.sort((a, b) => (a[field] < b[field] ? 1 : -1)));
    }
    setIsAscending(!isAscending);
  };

  function editPage(url) {
    navigate(`/edit/${url.source}`, {state: {
      url: url,
      urls: urls}});
  }

  return (
    <Table striped highlightOnHover >
      <thead>
        <tr>
          <th onClick={() => sortByField("source")} className="clickable">
            Short Link
          </th>
          <th>Target URL</th>
          <th onClick={() => sortByField("user_name")} className="clickable">
            User
          </th>
          <th onClick={() => sortByField("date_added")} className="clickable">
            Creation Date
          </th>
        </tr>
      </thead>
      <tbody>
        {urls.map((url) => (
          <tr
            onClick={() => editPage(url)}
            key={url.source}
            id={url.source}
          >
            <td >{url.source}</td>
            <td >{url.target}</td>
            <td>{url.user_name}</td>
            <td>{url.date_added}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default UrlTable;
