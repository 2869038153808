import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Form from "../components/Form";
import { Alert, Button, Group } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import Urls from "./Urls";
import { useSelector } from "react-redux";

const Home = () => {
  let { user, isAuthenticated, loginWithRedirect } = useAuth0();
  const { showMessage, message, color } = useSelector((state) => state.link);
  const [urls, setUrls] = useState([]);

  return (
    <div>
      {showMessage ? (
        <Alert radius="md" icon={<IconAlertCircle size={16} />} color={color}>
          {message}
        </Alert>
      ) : null}
      <h1>Hello, shortlink world!</h1>
      {!isAuthenticated ? (
        <div>
          <p>In order to create links please login.</p>
          <Button
            variant="outline"
            color="green"
            onClick={() =>
              loginWithRedirect({
                redirectUri: window.location.origin,
              })
            }
          >
            Log In
          </Button>
        </div>
      ) : (
        <div>
          <Form
            userName={user.name}
            urls={urls}
            setUrls={setUrls}
          />
        </div>
      )}
      <Urls urls={urls} setUrls={setUrls} />
    </div>
  );
};

export default Home;
